<template>
  <div class="container">
    <van-grid :border="true" :column-num="4">
      <van-grid-item
        v-for="(src, index) in smallImages"
        :key="index"
        @click="showPreview(index)"
      >
        <van-image :src="src" lazy-load />
      </van-grid-item>
    </van-grid>
    <van-image-preview
      v-model="show"
      :images="images"
      :startPosition="startPosition"
      @change="onChange"
    >
      <template v-slot:index>第{{ index + 1 }}页</template>
    </van-image-preview>
  </div>
</template>

<script>
import * as api from "@/apis/api";
import { ImagePreview } from "vant";
export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      show: false,
      index: 0,
      startPosition: 0,
      smallImages: [],
      images: [],
    };
  },
  created() {
    for (let i = 0; i < 46; i++) {
      this.smallImages[i] =
        api.getImgsUrl() + "/small/img" + i + ".jpg";
      this.images[i] = api.getImgsUrl() + "/img" + i + ".jpg";
    }
  },
  methods: {
    onChange(index) {
      this.index = index;
    },
    showPreview(index) {
      this.index = index;
      this.startPosition = index;
      this.show = true;
    },
  },
};
</script>

<style scoped>
</style>